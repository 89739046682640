import Image from "next/image";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

const alternativesList = [
    {
        name: "Prilosec (omeprazole) ",
        image: "prilosecOpt",
        description:
            "A proton pump inhibitor (PPI) medication commonly used to treat stomach acid-related conditions, including gastroesophageal reflux disease (GERD).",
    },
    {
        name: "Nexium (esomeprazole)",
        image: "nexiumOpt",
        description:
            " A proton pump inhibitor (PPI) that reduces stomach acid production and treats GERD, ulcers, and other conditions.",
    },
    {
        name: "Prevacid (lansoprazole)",
        image: "prevacidOpt",
        description:
            " A proton pump inhibitor (PPI) recommended for short-term ulcer and acid reflux treatment.",
    },
    {
        name: "Pepcid (famotidine)",
        image: "pepcidOpt",
        description:
            " An H2 blocker that treats ulcers, GERD, and conditions that cause excess stomach acid.",
    },
    {
        name: "Tagamet (cimetidine)",
        image: "tagametOpt",
        description:
            " An H2 blocker that reduces acid in the stomach to treat ulcers and acid reflux.",
    },
];

export default function ZantacReplacement(): ReactElement {
    return (
        <div className={`${styles.wrapper}`}>
            <div className="mx-auto lg:max-w-7xl flex justify-center items-start flex-col px-8">
                <h2>What Is a Safe Zantac Replacement?</h2>
                <p>
                    Deciding on a safe Zantac replacement is something you
                    should discuss with your doctor. The FDA recommends the
                    following Zantac alternatives:
                </p>
                <ul className="flex flex-wrap flex-col justify-start mt-12 gap-6 md:mx-8">
                    {alternativesList.map((alternative, index) => (
                        <li
                            key={index}
                            className="flex flex-row justify-start items-center  mb-8 md:mb-0"
                        >
                            <div className="relative w-[85px] h-[85px] mr-6 md:mr-24 mb-0">
                                <Image
                                    src={`/bestzantaclawyers/assets/images/${alternative.image}.png`}
                                    alt={alternative.name}
                                    layout="fill"
                                />
                            </div>
                            <p className="w-10/12 text-left">
                                <span>{alternative.name}</span>
                                {alternative.description}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
